import React from "react"

import DocumentLayout from "../../components/layout/document"

export default () => {
  return (
    <DocumentLayout title="Metadata">
      <div className="headline">
        <h1 className="text-primary m-0">Metadata</h1>
      </div>
      <p>
        In some of APIs endpoint that have a <span className="reference">metadata</span> field, user can user this
        field to attach key-value data for storing additional information. The
        key is up to user to define excepts reserved keyword.
      </p>

      <h2 className="h3">Reserved Keywords</h2>
      <p>Application should not use the keywords as defined at below, to avoid conflict.</p>
      <ul>
        <li><span className="reference">type</span></li>
        <li><span className="reference">id</span></li>
        <li><span className="reference">metadata</span></li>
      </ul>
    </DocumentLayout>
  )
}
